<template>
  <div style="margin-top: 5%">
<!--    上传工资表-->
    <div class="divcenter">
      <el-upload
          ref="upload"
          class="upload-demo"
          action="https://www.cr12cz.cn/czscsms/up/upload"
          :auto-upload="false"
          :data="dataup"
          :on-change="imgchange"
          :on-remove="handleRemove"
          :multiple="false"
          :on-success="upsuccess"
          :limit="1"
      >
        <template #trigger>
          <el-button type="primary">请选择上传工资表</el-button>
        </template>

        <el-tag style="margin-left: 20px" size="" type="info">工区</el-tag>
        <el-select style="margin-left: 5px;margin-bottom: 20px;margin-right: 20px" v-model="oper" placeholder="请选择标段">
          <el-option
              v-for="item in bdsts"
              :key="item.bdstname"
              :label="item.bdstname"
              :value="item.bdstname"
          >
          </el-option>
        </el-select>
        <el-date-picker
            v-model="value2"
            type="month"
            placeholder="请选择上传工资表月份"
            @change="getmonth()"
            value-format="YYYY-MM"
        />
        <div>
          <el-button
              style="width: 480px"
              type="success"
              @click="submitUpload"
          >上传
          </el-button>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";
import {setNowTimes} from "@/utils/time";
export default {
  inject: ['reload'],
  name: "salaryup",
  data(){
    return{
      fileList: [],
      dataup: {
        file: '',
        userid: ''
      },
      oper: '',
      bdsts: [],
      value2: ''
    }
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      console.log(this.bdsts)
    },{
      bdst: 1
    });
  },
  computed: {
    ...mapGetters(['GET_USERID'])
  },
  methods: {
    submitUpload() {
      if (this.oper === ''){
        ElMessage.error("请选择要对应的工区")
      }else {
        this.dataup.file = this.fileList[0].raw;
        this.dataup.userid = this.GET_USERID;
        this.$refs.upload.submit();
      }

    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    // 导入
     leadin(){
       this.axios.post('/salary/leadinsal', (response2) => {
         if(response2.obj === '人员不全'){
           ElMessage.error(response2.obj + '不在用户表中，请完善！');
         }else{
           ElMessage({
             message: '导入成功！',
             type: 'success',
           })
         }
       },{
         file: this.dataup.file,
         month: this.value2,
         bdst: this.oper
       })
     },
    upsuccess(response) {
      if (response.obj) {
        let arr = response.obj.split("/");
        let filename = arr[arr.length - 1];
        this.axios.post('/salaryFile/save', (response1) => {
          console.log(response1.obj)
          if (response1.obj){
            this.reload();
            this.leadin();

          }
        },{
          fileName: filename,
          uploadTime: setNowTimes(new Date()),
          salaryMonth: this.value2,
          uploadEmployeeNo: this.GET_USERID,
          fileUrl: response.obj
        })
      }
    },
    getmonth(){
      console.log(this.value2)
    }
  }
}
</script>

<style scoped>
.divcenter {
  width: 800px;
  height: 500px;
  padding-top: 200px;
  margin: auto;
}
</style>